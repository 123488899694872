import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useGetFundByIdQuery } from '@features/coInnovationFunds/funds/hooks/useGetFundByIdQuery';
import { namespaces } from '@shared/constants';
import { TextArea } from '@shared/form';
import { formatDate, formatNumber } from '@shared/formatting';
import { Contact } from '@shared/ui';

type Props = {
    id: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
};

export const FundValidationForm = ({ id, setComment }: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { data } = useGetFundByIdQuery(id);

    if (!data) {
        return <></>;
    }

    const { beginDate, consumptionExpirationDate, partner, provided, submittedByContact, submissionComment } = data;

    const sections = [
        {
            title: t('coInnovationFunds.validationFundsForm.partnerLabel'),
            value: partner.name,
        },
        {
            title: t('coInnovationFunds.validationFundsForm.submitter'),
            value: submittedByContact.name,
        },
        {
            title: t('coInnovationFunds.validationFundsForm.providedLabel'),
            value: `${formatNumber(provided)} CHF`,
        },
        {
            title: t('coInnovationFunds.validationFundsForm.beginDateLabel'),
            value: formatDate(beginDate),
        },
        {
            title: t('coInnovationFunds.validationFundsForm.consumptionDeadlineLabel'),
            value: formatDate(consumptionExpirationDate),
        },
    ];

    return (
        <form onSubmit={e => e.preventDefault()}>
            <div>
                <div className="mb-4 grid w-full grid-cols-1 flex-wrap gap-3 md:flex-nowrap md:justify-between">
                    {sections.map((section, index) => (
                        <div
                            key={index}
                            className={classNames(
                                'flex flex-grow justify-between rounded-lg border-2 border-blue-100 py-4 px-3 text-left md:flex-auto'
                            )}
                        >
                            <div>
                                <p className="mb-[6px] text-base">{section.title}</p>
                                <p className="text-sm font-light">{section.value}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="rounded-lg border-2 border-blue-100 py-2 px-3">
                    <div>
                        <div className="mt-3 flex items-center">
                            <div className="mb-2 items-center sm:flex ">
                                <div className="mb-3 sm:mb-0">
                                    <Contact name={submittedByContact.name} photo={undefined} />
                                </div>
                                {submissionComment?.creationDate ? (
                                    <span className="text-sm font-light sm:ml-4">
                                        {formatDate(submissionComment.creationDate)}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="mt-1 text-sm">{submissionComment?.text}</div>
                    </div>
                    <div className="mt-4 mb-2">{`${t('coInnovationFunds.validationFundsForm.comments')}*`}</div>
                    <div>
                        <TextArea
                            label={`${t('coInnovationFunds.validationFundsForm.comments')}*`}
                            placeholder={t('coInnovationFunds.validationFundsForm.commentMessage')}
                            onChange={e => setComment(e.target.value)}
                            rows={4}
                            required={true}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};
