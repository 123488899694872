export const fundStatuses = {
    pending: 0,
    approved: 1,
    rejected: 2,
    canceled: 3,
    changeRequestPending: 4,
};

export const fundStatusLabels = {
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    canceled: 'Canceled',
    changeRequestPending: 'Change pending',
};

export const getFundStatusTranslationKey = (statusId: number) => {
    switch (statusId) {
        case fundStatuses.pending:
            return 'coInnovationFunds.fundsManagement.statuses.pending';

        case fundStatuses.approved:
            return 'coInnovationFunds.fundsManagement.statuses.approved';

        case fundStatuses.rejected:
            return 'coInnovationFunds.fundsManagement.statuses.rejected';

        case fundStatuses.canceled:
            return 'coInnovationFunds.fundsManagement.statuses.canceled';

        case fundStatuses.changeRequestPending:
            return 'coInnovationFunds.fundsManagement.statuses.cRPending';

        default:
            return 'coInnovationFunds.fundsManagement.statuses.unknown';
    }
};
