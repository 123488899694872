import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { CoInnovationFund, SelectedFundState } from '../domain';
import { useCrCancelMutation } from '../hooks/useCrCancelMutation';

import { ReactComponent as InfoIcon } from '@assets/icons/info.svg';
import { namespaces } from '@shared/constants';
import { formatDate, formatNumber } from '@shared/formatting';
import {
    Link,
    Button,
    Spinner,
    DialogBody,
    DialogError,
    DialogFooter,
    DialogHeader,
    DialogContainer,
} from '@shared/ui';
import { useStore } from '@store';

type Props = {
    selectedFund?: CoInnovationFund;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
    onSuccessToast?: () => void;
    onErrorToast?: () => void;
};

export const FundChangeRequestCancelDialog = ({
    setSelectedFundState,
    selectedFund,
    onSuccessToast,
    onErrorToast,
}: Props) => {
    const { t } = useTranslation();
    const { t: fundsManagementT } = useTranslation(namespaces.features.coInnovationFunds);

    const supportUrl = useStore(state => state.serviceNow.supportUrl);

    const [backendError, setBackendError] = useState(false);

    const queryClient = useQueryClient();
    const { mutate: mutateCancelFund, isLoading } = useCrCancelMutation({
        fundId: selectedFund?.id || '',
        onSuccess: () => {
            setBackendError(false);
            setSelectedFundState(previousState => ({
                id: selectedFund?.id || '',
                types: previousState?.types?.filter(type => type !== 'cancelCR') || [],
            }));
            queryClient.invalidateQueries(['summary']);
            queryClient.invalidateQueries(['summaryPartners']);
            queryClient.invalidateQueries(['summaryPartnersHistory']);
            queryClient.invalidateQueries(['fundsList']);
            queryClient.invalidateQueries(['fund']);
            onSuccessToast && onSuccessToast();
        },
        onError: () => {
            setBackendError(true);
            onErrorToast && onErrorToast();
        },
    });

    const hideDialog = useCallback(() => {
        setSelectedFundState(previousState => ({
            id: selectedFund?.id || '',
            types: previousState?.types?.filter(type => type !== 'cancelCR') || [],
        }));
    }, [setSelectedFundState, selectedFund?.id]);

    if (!selectedFund) {
        return null;
    }
    const { provided, carryForward, partner, submissionDate } = selectedFund;

    const sections = [
        {
            title: fundsManagementT('coInnovationFunds.fundsManagement.submitDate'),
            value: formatDate(submissionDate),
        },
        {
            title: fundsManagementT('coInnovationFunds.fundsManagement.partner'),
            value: partner.name,
        },
        {
            title: fundsManagementT('coInnovationFunds.fundsManagement.provided'),
            value: `${formatNumber(provided)} CHF`,
        },
        {
            title: fundsManagementT('coInnovationFunds.validationFundsForm.carryForwardLabel'),
            value: `${formatNumber(carryForward)} CHF`,
        },
    ];

    return (
        <DialogContainer
            setShowDialog={hideDialog}
            showDialog={!!selectedFund}
            size="medium"
            aria-label="Cancel Change Request Dialog"
        >
            <DialogHeader onCloseButtonClick={hideDialog}>
                {fundsManagementT('coInnovationFunds.fundsManagement.cancelChangeRequest.title')}

                <span className="ml-2 inline-block align-middle text-error [&_svg]:h-4 [&_svg]:w-4">
                    <InfoIcon title="info-icon" />
                </span>
            </DialogHeader>

            <DialogBody>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <div className="mx-auto max-w-[420px]">
                        <div className="mb-4 grid w-full grid-cols-1 flex-wrap gap-3 md:flex-nowrap md:justify-between">
                            {sections.map((section, index) => (
                                <div
                                    key={index}
                                    className={classNames(
                                        'flex flex-grow justify-between rounded-lg border-2 border-blue-100 py-4 px-3 text-left md:flex-auto'
                                    )}
                                >
                                    <div>
                                        <p className="mb-[6px] text-base">{section.title}</p>
                                        <p className="text-sm font-light">{section.value}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <p className="bg-red-100 p-4 text-sm font-light">
                            <span className="mr-2 inline-block align-middle text-error [&_svg]:h-4 [&_svg]:w-4">
                                <InfoIcon />
                            </span>
                            {fundsManagementT('coInnovationFunds.fundsManagement.cancelChangeRequest.instruction')}
                        </p>
                    </div>
                )}
            </DialogBody>

            <DialogFooter noPadding>
                <div className="grid gap-3 p-5 md:block [&_button]:py-[8.5px] [&_button]:text-xs [&_button[type='submit']]:py-[8.5px] [&_button[type='submit']]:text-xs">
                    <Button variant="secondary" className="flex-grow md:mr-2" type="reset" onClick={hideDialog}>
                        {t('forms.cancelFunds')}
                    </Button>
                    <Button variant="primary" className="flex-grow" type="submit" onClick={() => mutateCancelFund()}>
                        {fundsManagementT('coInnovationFunds.fundsManagement.cancelChangeRequest.proceed')}
                    </Button>
                </div>

                {backendError && !isLoading ? (
                    <DialogError
                        title={t('errors.addFunds.title')}
                        instruction={
                            <Trans
                                t={t}
                                i18nKey="errors.instruction"
                                components={{
                                    anchor: (
                                        <Link
                                            to={supportUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            noPadding
                                            alignBaseLine
                                        />
                                    ),
                                }}
                            />
                        }
                    />
                ) : null}
            </DialogFooter>
        </DialogContainer>
    );
};
