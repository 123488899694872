import { fundStatuses } from '../constants';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import ActionButton from '@shared/ui/button/ActionButton';
import { useStore } from '@store';

enum ActionType {
    Cancel = 'cancel',
    Approve = 'approve',
    Reject = 'reject',
    ApproveCR = 'approveCR',
    RejectCR = 'rejectCR',
    CancelCR = 'cancelCR',
    ChangeRequest = 'changeRequest',
}

type FundDetailActionsProps = {
    statusId?: number;
    handleActionClick: (actionType: ActionType) => void;
    requester?: string;
};

export const FundDetailActions = ({ statusId, handleActionClick, requester }: FundDetailActionsProps) => {
    const account = useStore(state => state.auth.account);

    if (statusId === undefined) {
        return null;
    }

    const canCancelFundsPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];
    const canValidateFundsPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

    const canChangeRequestPermissions = [
        permissions.coInnovationFunds.allRequests.create,
        permissions.coInnovationFunds.partnerRequests.create,
    ];

    const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

    return (
        <>
            {[fundStatuses.pending].includes(statusId) && (
                <>
                    <HasPermission neededPermissions={canValidateFundsPermissions}>
                        <ActionButton
                            onClickAction={() => handleActionClick(ActionType.Approve)}
                            type="approve"
                            iconPosition="left"
                        />
                    </HasPermission>

                    <HasPermission neededPermissions={canValidateFundsPermissions}>
                        <ActionButton
                            onClickAction={() => handleActionClick(ActionType.Reject)}
                            type="reject"
                            iconPosition="left"
                        />
                    </HasPermission>
                    {requester === account?.username && (
                        <HasPermission neededPermissions={canCancelFundsPermissions}>
                            <ActionButton
                                onClickAction={() => handleActionClick(ActionType.Cancel)}
                                type="cancel"
                                iconPosition="left"
                            />
                        </HasPermission>
                    )}
                </>
            )}
            {[fundStatuses.changeRequestPending].includes(statusId) && (
                <>
                    <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                        <ActionButton
                            onClickAction={() => handleActionClick(ActionType.ApproveCR)}
                            type="approve"
                            iconPosition="left"
                        />
                    </HasPermission>

                    <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                        <ActionButton
                            onClickAction={() => handleActionClick(ActionType.RejectCR)}
                            type="reject"
                            iconPosition="left"
                        />
                    </HasPermission>
                    {requester === account?.username && (
                        <HasPermission neededPermissions={canCancelFundsPermissions}>
                            <ActionButton
                                onClickAction={() => handleActionClick(ActionType.CancelCR)}
                                type="cancel"
                                iconPosition="left"
                            />
                        </HasPermission>
                    )}
                </>
            )}
            {[fundStatuses.approved].includes(statusId) && (
                <>
                    <HasPermission neededPermissions={canChangeRequestPermissions}>
                        <ActionButton
                            onClickAction={() => handleActionClick(ActionType.ChangeRequest)}
                            type="request"
                            iconPosition="left"
                        />
                    </HasPermission>
                </>
            )}
        </>
    );
};
