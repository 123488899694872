import { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { handleLogout } from '../handlers';

import { ReactComponent as Chart } from '@assets/icons/chart-pie.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import { ReactComponent as Union } from '@assets/icons/Union.svg';
import userSvgUrl from '@assets/icons/user.svg';
import { HasPermission, permissions } from '@features/authorization';
import { namespaces } from '@shared/constants';
import { Dropdown } from '@shared/ui';
import { DetailTooltip } from '@shared/ui/tooltip/DetailTooltip';

type Props = {
    photo?: string;
    name: string;
};

const Avatar = ({ photo, name }: Props) => {
    const { instance } = useMsal();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { t: landing } = useTranslation(namespaces.features.landing);
    const location = useLocation();
    const [showTooltip, setShowTooltip] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [lastScrollY, setLastScrollY] = useState(0);

    const handleCoInnovation = () => {
        navigate('/co-innovation-funds');
    };

    const handleDropdownOpen = () => {
        setIsDropdownOpen(prevState => !prevState);
        setShowTooltip(prevState => !prevState);
    };
    const handleDropdownToggle = () => {
        setShowTooltip(prevState => !prevState);
    };

    const handleMouseClose = () => {
        setShowTooltip(false);
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setShowTooltip(currentScrollY < lastScrollY || currentScrollY === 0);
        setLastScrollY(currentScrollY);
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>
            <div className="avatar hidden sm:inline-flex">
                <Dropdown
                    title={
                        <div
                            className="inline-flex cursor-pointer items-center py-3 text-black transition-colors"
                            data-testid="logout-user-profile"
                            onClick={handleDropdownToggle}
                            onMouseEnter={handleMouseClose}
                        >
                            <span
                                className={classNames(
                                    'flex h-8 w-8 overflow-hidden rounded-full border border-blue-850',
                                    {
                                        'items-center justify-center bg-white': !photo,
                                    }
                                )}
                            >
                                <img
                                    src={photo ? `data:image/jpg;base64,${photo}` : userSvgUrl}
                                    alt="avatar image"
                                    className={classNames({
                                        'h-full max-w-none': photo,
                                    })}
                                />
                            </span>
                        </div>
                    }
                    dropdownClassNames="w-full flex justify-end bg-transparent right-0 top-full sm:absolute"
                    isClicked={isDropdownOpen}
                >
                    <div className="border-3 mr-1 flex flex-col gap-2 whitespace-nowrap rounded-lg bg-white p-3 text-xs shadow-elevation-l">
                        <span className="body-s-bold p-3">{name}</span>

                        <HasPermission neededPermissions={[permissions.nestleAccount.view]}>
                            <button
                                type="button"
                                className="body-s-book flex rounded bg-basic-blue p-3  text-white"
                                onClick={() => handleCoInnovation()}
                                data-testid="logout-button"
                            >
                                <i>
                                    <Chart />
                                </i>
                                <span className="body-s-book ml-2 mt-1">{t('header.coInnovationFunds')}</span>
                            </button>
                        </HasPermission>

                        <button
                            type="button"
                            className="body-s-book flex items-center p-3"
                            onClick={() => handleLogout({ instance })}
                            data-testid="logout-button"
                        >
                            <i className="text-blue-850">
                                <LogoutIcon />
                            </i>

                            <span className="body-s-book ml-2">{t('account.logout')}</span>
                        </button>
                    </div>
                </Dropdown>
                <HasPermission neededPermissions={[permissions.nestleAccount.view]}>
                    <>
                        {location.pathname.toLowerCase() === '/prehome' && showTooltip && (
                            <DetailTooltip
                                title={landing('detailTooltip.title')}
                                description={landing('detailTooltip.description')}
                                IconComponent={Union}
                                onClick={handleDropdownOpen}
                            />
                        )}
                    </>
                </HasPermission>
            </div>

            <button
                type="button"
                className="body-s-book static inline-flex gap-2 sm:hidden"
                onClick={() => handleLogout({ instance })}
                data-testid="logout-button-small-screen"
            >
                <i className="text-blue-850">
                    <LogoutIcon />
                </i>
                <span>{t('account.logout')}</span>
            </button>
        </>
    );
};

export { Avatar };
