import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as ApproveIcon } from '@assets/icons/approveAction.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as RejectIcon } from '@assets/icons/rejectAction.svg';
import { namespaces } from '@shared/constants';
import { useStore } from '@store';

const TIMEOUT_DURATION = 2000;

const TOAST_CONTENT = {
    approve: {
        toastClasses: 'bg-semantic-green-15 text-semantic-green-100 border-semantic-green-100',
        icon: <ApproveIcon />,
        buttonClasses: 'border-semantic-green-100',
    },
    reject: {
        toastClasses: 'bg-semantic-purple-15 text-secondary-purple border-secondary-purple',
        icon: <RejectIcon />,
        buttonClasses: 'border-semantic-purple-30',
    },
};

const toastBaseClasses =
    'fixed top-4 right-4 mt-header-height flex h-[60px] w-[380px] items-center justify-between rounded border-l-8 p-4 shadow-elevation-m';

export const ToastMessage = () => {
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const { t } = useTranslation(namespaces.toast);
    const { toastInfo, setToastInfo } = useStore(state => state.toast);
    const { type, message } = toastInfo;

    useEffect(() => {
        if (!type) {
            return;
        }

        timerRef.current = setTimeout(() => {
            setToastInfo({ type: '', message: '' });
        }, TIMEOUT_DURATION);

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [type, setToastInfo]);

    if (!type) {
        return null;
    }

    const toastData = TOAST_CONTENT[type];

    if (!toastData) {
        return null;
    }

    return (
        <div role="alert" className={`${toastBaseClasses} ${toastData.toastClasses}`}>
            <div className="flex items-center gap-2">
                {toastData.icon}
                <span className="body-s-bold">{t(message)}</span>
            </div>
            <button
                className={`ml-4 border-l-[1px] ${toastData.buttonClasses} py-3 pr-3 pl-6 text-xl font-bold`}
                onClick={() => setToastInfo({ type: '', message: '' })}
                aria-label="Close toast message"
            >
                <CloseIcon />
            </button>
        </div>
    );
};
