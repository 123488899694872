import { useEffect, Suspense, lazy } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import LandingPage from './LandingPage';

import { HasPermission, permissions } from '@features/authorization';
import { namespaces } from '@shared/constants';
import { SegmentedButton } from '@shared/ui/segmentedButton/SegmentedButton';

// Lazy-load components
const Discovery = lazy(() => import('@features/discovery/Discovery').then(module => ({ default: module.Discovery })));
const Initiatives = lazy(() =>
    import('@features/initiatives/list/Initiatives').then(module => ({ default: module.Initiatives }))
);
const Ideation = lazy(() => import('@features/ideation/Ideation'));
const PartnersLanding = lazy(() => import('@features/landings/partnersLanding/PartnersLanding'));

const scrollToElement = (elementId: string, offset = 72) => {
    const element = document.getElementById(elementId);
    if (element) {
        const position = element.getBoundingClientRect().top + window.scrollY;

        // Temporary scroll reset to force browser to recognize scroll event
        window.scrollTo({
            top: window.scrollY + 1,
        });

        // Perform the actual scroll
        window.scrollTo({
            top: position - offset,
            behavior: 'smooth',
        });
    }
};

const SuspenseContent = ({ pageParam }: { pageParam: string }) => {
    return (
        <>
            {pageParam === 'discovery' && <Discovery />}
            {pageParam === 'ideation' && <Ideation />}
            {pageParam === 'initiatives' && <Initiatives />}
        </>
    );
};

const PreLandingPage = () => {
    const { t } = useTranslation(namespaces.features.landing);
    const location = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(location.search);
    const pageParam = (params.get('page') as 'discovery' | 'ideation' | 'initiatives') || 'discovery';

    // One-time observer for initial mount
    useEffect(() => {
        if (!params.has('page')) return;

        const observer = new MutationObserver(() => {
            const segmentedButton = document.getElementById('segmented-button');
            if (segmentedButton) {
                segmentedButton.scrollIntoView({ behavior: 'smooth', block: 'start' });
                observer.disconnect();
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    // Run everytime the params after ? change
    useEffect(() => {
        scrollToElement('segmented-button');
    }, [location.search]);

    const handleScrollToSegmentedButton = () => {
        const newUrl = `?page=${pageParam || 'discovery'}`;
        navigate(newUrl, { replace: true });
        scrollToElement('segmented-button');
    };

    const handlePageChange = (url: string) => {
        navigate(`?page=${url}`);
    };

    return (
        <>
            <HasPermission neededPermissions={[permissions.nestleAccount.view]}>
                <div className="scroll-smooth">
                    {/* Static Landing Page */}
                    <section id="preLanding" className="relative h-screen items-center justify-center">
                        <LandingPage onScrollToSegmentedButton={handleScrollToSegmentedButton} />
                    </section>

                    {/* Segmented Button */}
                    <div id="segmented-button" className="sticky top-[72px] z-10 bg-neutral-oak-5 px-[200px]">
                        <div className="flex flex-col items-center">
                            <div className="w-full max-w-[750px] pt-6">
                                <SegmentedButton
                                    options={[
                                        { name: 'Discovery', url: 'discovery', selected: pageParam === 'discovery' },
                                        { name: 'Ideation', url: 'ideation', selected: pageParam === 'ideation' },
                                        {
                                            name: 'Initiatives',
                                            url: 'initiatives',
                                            selected: pageParam === 'initiatives',
                                        },
                                    ]}
                                    onOptionClick={handlePageChange}
                                />
                            </div>
                            <p className="body-s-book py-6 text-neutral-oak-100">
                                {t('preLanding.FooterText.start')} <strong>{t('preLanding.FooterText.bold')}</strong>
                            </p>
                        </div>
                    </div>

                    {/* Dynamic Sections with Suspense */}
                    <div className="relative h-full w-full">
                        <div id="suspense-container" className="min-h-[calc(100vh-72px-136px)]">
                            <Suspense>
                                <SuspenseContent pageParam={pageParam} />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </HasPermission>

            <HasPermission neededPermissions={[permissions.partner.view]}>
                <Suspense fallback={<div>Loading PartnersLanding...</div>}>
                    <PartnersLanding />
                </Suspense>
            </HasPermission>
        </>
    );
};

export default PreLandingPage;
