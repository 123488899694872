import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { fundStatuses } from '../constants';

import { FundDetailActions } from './FundDetailActions';

import { CoInnovationFund, DialogTypes } from '@features/coInnovationFunds/funds/domain';
import { namespaces } from '@shared/constants';
import { formatNumber } from '@shared/formatting/formatNumber';
import { ContactBox } from '@shared/ui';

type PanelStatusDetailProps = {
    fund?: CoInnovationFund | undefined;
    onActionClick: (actionType: DialogTypes) => void;
};

export const PanelStatusDetail: FC<PanelStatusDetailProps> = ({ fund, onActionClick }) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const requester = useMemo(() => {
        if (fund?.changeRequests?.length) {
            return fund.changeRequests[fund.changeRequests.length - 1]?.submittedByContact?.email;
        }
        return fund?.submittedByContact?.email;
    }, [fund?.changeRequests, fund?.submittedByContact?.email]);

    const requestedTranslation = useMemo(() => t('coInnovationFunds.fundsManagement.requested'), [t]);
    const currency = useMemo(() => t('coInnovationFunds.currency'), [t]);

    const isPendingStatus = useMemo(() => fund?.statusId === fundStatuses.pending, [fund?.statusId]);

    const carryforwardValue = useMemo(
        () => (!isNaN(Number(fund?.carryForward)) ? formatNumber(Number(fund?.carryForward)) : fund?.carryForward),
        [fund?.carryForward]
    );

    const providedValue = useMemo(
        () => (!isNaN(Number(fund?.provided)) ? formatNumber(Number(fund?.provided)) : fund?.provided),
        [fund?.provided]
    );

    const expiredValue = useMemo(
        () => (!isNaN(Number(fund?.expired)) ? formatNumber(Number(fund?.expired)) : fund?.expired),
        [fund?.expired]
    );

    const GetStatusByStatusInt = useMemo(() => {
        const statusMap: Record<number, string> = {
            [fundStatuses.pending]: t('coInnovationFunds.fundsManagement.statuses.pending'),
            [fundStatuses.approved]: t('coInnovationFunds.fundsManagement.statuses.approved'),
            [fundStatuses.rejected]: t('coInnovationFunds.fundsManagement.statuses.rejected'),
            [fundStatuses.canceled]: t('coInnovationFunds.fundsManagement.statuses.canceled'),
            [fundStatuses.changeRequestPending]: t('coInnovationFunds.fundsManagement.statuses.cRPending'),
        };
        return (status?: number) => {
            if (status === undefined || status === null) {
                return t('coInnovationFunds.fundsManagement.statuses.unknown');
            }
            return statusMap[status] ?? t('coInnovationFunds.fundsManagement.statuses.unknown');
        };
    }, [t]);

    return (
        <div className="flex gap-3 rounded-lg bg-gray-50 py-6">
            <div className="flex-auto px-8 text-left">
                <p className="body-l-bold text-neutral-oak-dark">{t('coInnovationFunds.fundsManagement.status')}</p>
                <p className="body-s-book pt-3 leading-6 text-basic-blue">{requestedTranslation}</p>
                <p className="body-l-book pt-0.5">{GetStatusByStatusInt(fund?.statusId)}</p>
            </div>
            {isPendingStatus && (
                <div className="flex-auto border-l-2 border-neutral-oak-15 px-8 text-left">
                    <p className="body-l-bold text-neutral-oak-dark">{t('coInnovationFunds.fundsManagement.user')}</p>
                    <p className="body-s-book pt-3 leading-6 text-basic-blue">
                        {t('coInnovationFunds.fundsManagement.requester')}
                    </p>
                    <div className="body-l-book pt-0.5">
                        <ContactBox
                            email=""
                            name={fund?.submittedByContact.name ?? ''}
                            photo={fund?.submittedByContact.photo ?? ''}
                            showMailIcon={false}
                        />
                    </div>
                </div>
            )}
            <div className="flex-auto border-l-2 border-neutral-oak-15 px-8 text-left">
                <p className="body-l-bold text-neutral-oak-dark">
                    {t('coInnovationFunds.fundsManagement.providedFunds')}
                </p>
                <p className="body-s-book pt-3 leading-6 text-basic-blue">{requestedTranslation}</p>
                <p className={isPendingStatus ? 'body-l-bold' : 'body-l-book'}>
                    {providedValue} {currency}
                </p>
            </div>
            <div className="flex-auto border-l-2 border-neutral-oak-15 px-8 text-left">
                <p className="body-l-bold text-neutral-oak-dark">
                    {t('coInnovationFunds.fundsManagement.carryForward')}
                </p>
                <p className="body-s-book pt-3 leading-6 text-basic-blue">{requestedTranslation}</p>
                <p className={isPendingStatus ? 'body-l-bold' : 'body-l-book'}>
                    {carryforwardValue} {currency}
                </p>
            </div>
            {!isPendingStatus && (
                <div className="flex-auto border-l-2 border-neutral-oak-15 px-8 text-left">
                    <p className="body-l-bold text-neutral-oak-dark">
                        {t('coInnovationFunds.fundsManagement.expiredFunds')}
                    </p>
                    <p className="body-s-book pt-3 leading-6 text-basic-blue">{requestedTranslation}</p>
                    <p className="body-l-book pt-0.5">
                        {expiredValue} {currency}
                    </p>
                </div>
            )}
            {![fundStatuses.rejected, fundStatuses.canceled].includes(fund?.statusId || 8) && (
                <div
                    aria-label="Fund Details Actions"
                    className="flex items-center justify-center gap-3 border-l-2 border-neutral-oak-15 px-8 text-left"
                >
                    <FundDetailActions
                        requester={requester}
                        statusId={fund?.statusId}
                        handleActionClick={onActionClick}
                    />
                </div>
            )}
        </div>
    );
};
