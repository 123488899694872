import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CoInnovationFundListItem } from '../domain';
import { useGetActveCrByFundIdQuery } from '../hooks/useGetActveCrByFundIdQuery';

import { namespaces } from '@shared/constants';
import { formatNumber } from '@shared/formatting';
import { Contact } from '@shared/ui';

type Props = {
    id: string;
    selectedFund: CoInnovationFundListItem;
};

export const ChangeRequestValidationForm = ({ id, selectedFund }: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { t: commonT } = useTranslation();

    const { data } = useGetActveCrByFundIdQuery(id);

    if (!data) {
        return <></>;
    }

    const { provided, expired, carriedForward, submittedByContact, comment, submissionDate } = data;

    const sections = [
        {
            title: t('coInnovationFunds.validationFundsForm.providedLabel'),
            current: `${formatNumber(selectedFund.provided)} CHF`,
            requested: `${formatNumber(provided)} CHF`,
        },
        {
            title: t('coInnovationFunds.validationFundsForm.carryForwardLabel'),
            current: `${formatNumber(selectedFund.carryForward)} CHF`,
            requested: `${formatNumber(carriedForward)} CHF`,
        },
        {
            title: t('coInnovationFunds.validationFundsForm.expiredLabel'),
            current: `${formatNumber(selectedFund.expired)} CHF`,
            requested: `${formatNumber(expired)} CHF`,
        },
    ];

    const formatDate = (text: string) => {
        return new Date(text).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });
    };

    return (
        <form onSubmit={e => e.preventDefault()}>
            <div className="grid w-full grid-cols-4">
                <div className="col-span-1 mt-7 justify-between py-4 text-left text-sm">
                    <div>{commonT('Current')}</div>
                    <div>{commonT('Requested')}</div>
                </div>
                <div className="col-span-3 mb-4 grid w-full grid-cols-3 flex-wrap gap-3 md:flex-nowrap md:justify-between">
                    {sections.map((section, index) => (
                        <div
                            key={index}
                            className={classNames(
                                'flex flex-grow justify-between rounded-lg border-blue-100 py-4 px-3 text-left md:flex-auto'
                            )}
                        >
                            <div>
                                <p key="1" className="mb-[6px]  ">
                                    {section.title}
                                </p>
                                <p key="2" className="text-sm font-light">
                                    {section.current}
                                </p>
                                <p
                                    key="3"
                                    className={classNames('text-sm font-light', { 'text-blue-900': index === 0 })}
                                >
                                    {section.requested}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {comment && (
                <div>
                    <div className="mt-2  flex items-center">
                        <div className="mb-2 items-center sm:flex ">
                            <div className="mb-3 flex gap-2 font-extralight sm:mb-0">
                                <Contact name={submittedByContact} photo={undefined} />
                                <div className=" mt-1 font-light">{formatDate(submissionDate)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-1 pb-7 text-sm">{comment}</div>
                </div>
            )}
        </form>
    );
};
