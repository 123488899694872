import { FC, useMemo, useCallback } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { fundStatuses } from '../constants';

import { FundDetailActions } from './FundDetailActions';

import { CoInnovationFund, DialogTypes } from '@features/coInnovationFunds/funds/domain';
import { useGetFundChangeRequestById } from '@features/coInnovationFunds/funds/hooks/useGetFundChangeRequestById';
import { namespaces } from '@shared/constants';
import { formatNumber } from '@shared/formatting/formatNumber';
import { ContactBox } from '@shared/ui';

type PanelStatusDetailRequestProps = {
    fund: CoInnovationFund | undefined;
    lastStatus: number;
    onActionClick: (actionType: DialogTypes) => void;
};

export const PanelStatusDetailRequestChange: FC<PanelStatusDetailRequestProps> = ({
    fund,
    lastStatus,
    onActionClick,
}) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { data: requestFund } = useGetFundChangeRequestById(fund?.id ?? '');

    const requestedTranslation = useMemo(() => t('coInnovationFunds.fundsManagement.requested'), [t]);
    const valueClasses = useMemo(() => 'pt-0.5 leading-6', []);
    const labelClasses = useMemo(() => 'pt-6 leading-6 text-basic-blue font-normal', []);
    const currentTranslation = useMemo(() => t('coInnovationFunds.fundsManagement.current'), [t]);
    const currency = useMemo(() => t('coInnovationFunds.currency'), [t]);

    const requester = useMemo(() => {
        if (fund?.changeRequests?.length) {
            return fund.changeRequests[fund.changeRequests.length - 1]?.submittedByContact?.email;
        }
        return fund?.submittedByContact?.email;
    }, [fund?.changeRequests, fund?.submittedByContact?.email]);

    const valueHasChanged = useMemo(() => {
        return (value1: number | undefined, value2: number | undefined, size: 'l' | 'm' | 's'): string => {
            const sizeClassMap: Record<'l' | 'm' | 's', string> = {
                l: 'body-l',
                m: 'body-m',
                s: 'body-s',
            };

            const sizeClass = sizeClassMap[size] ?? '';

            return value1 === value2 ? `text-neutral-oak-45 ${sizeClass}-book` : `${sizeClass}-bold`;
        };
    }, []);

    const getStatusByStatusInt = useCallback(
        (status?: number): string => {
            const statusMap: Record<number, string> = {
                [fundStatuses.pending]: t('coInnovationFunds.fundsManagement.statuses.pending'),
                [fundStatuses.approved]: t('coInnovationFunds.fundsManagement.statuses.approved'),
                [fundStatuses.rejected]: t('coInnovationFunds.fundsManagement.statuses.rejected'),
                [fundStatuses.canceled]: t('coInnovationFunds.fundsManagement.statuses.canceled'),
                [fundStatuses.changeRequestPending]: t('coInnovationFunds.fundsManagement.statuses.cRPending'),
            };

            return statusMap[status ?? -1] ?? t('coInnovationFunds.fundsManagement.statuses.unknown');
        },
        [t]
    );

    return (
        <div className="flex gap-3 rounded-lg bg-gray-50 py-6">
            <div className="px-5 text-left">
                <p className="body-l-bold text-neutral-oak-dark">{t('coInnovationFunds.fundsManagement.user')}</p>
                <p className={`body-s-book ${labelClasses}`}>{t('coInnovationFunds.fundsManagement.requester')}</p>
                <div className="body-l-book pt-0.5">
                    <ContactBox
                        email={''}
                        name={fund?.submittedByContact.name ?? ''}
                        photo={fund?.submittedByContact.photo ?? ''}
                        showMailIcon={false}
                    />
                </div>
            </div>

            <div className="flex-auto border-l-2 border-neutral-oak-15 px-5 text-left">
                <p className="body-l-bold text-neutral-oak-dark">{t('coInnovationFunds.fundsManagement.status')}</p>
                <p className="body-s-book pt-3 leading-6 text-basic-blue">{currentTranslation}</p>
                <p className="body-l-book pt-0.5">{getStatusByStatusInt(lastStatus)}</p>
                <p className={`body-s-book ${labelClasses}`}>{requestedTranslation}</p>
                <p className="body-l-bold pt-0.5">{getStatusByStatusInt(fund?.statusId)}</p>
            </div>

            <div className="flex-auto border-l-2 border-neutral-oak-15 px-5 text-left">
                <p className="body-l-bold text-neutral-oak-dark">
                    {t('coInnovationFunds.fundsManagement.providedFunds')}
                </p>
                <p className="body-s-book pt-3 leading-6 text-basic-blue">{currentTranslation}</p>

                <p className="body-l-book pt-0.5">
                    {!isNaN(Number(fund?.provided)) ? formatNumber(Number(fund?.provided)) : fund?.provided}
                    {currency}
                </p>

                <p className={classNames(valueHasChanged(fund?.provided, requestFund?.provided, 's'), labelClasses)}>
                    {requestedTranslation}
                </p>
                <p className={classNames(valueClasses, valueHasChanged(fund?.provided, requestFund?.provided, 'l'))}>
                    {!isNaN(Number(requestFund?.provided))
                        ? formatNumber(Number(requestFund?.provided))
                        : requestFund?.provided}
                    {currency}
                </p>
            </div>

            <div className="flex-auto border-l-2 border-neutral-oak-15 px-5 text-left">
                <p className="body-l-bold text-neutral-oak-dark">
                    {t('coInnovationFunds.fundsManagement.carryForward')}
                </p>
                <p className="body-s-book pt-3 leading-6 text-basic-blue">{currentTranslation}</p>

                <p className="body-l-book leading-6">
                    {!isNaN(Number(fund?.carryForward)) ? formatNumber(Number(fund?.carryForward)) : fund?.carryForward}
                    {currency}
                </p>

                <p
                    className={classNames(
                        labelClasses,
                        valueHasChanged(fund?.carryForward, requestFund?.carriedForward, 's')
                    )}
                >
                    {requestedTranslation}
                </p>
                <p
                    className={classNames(
                        valueClasses,
                        valueHasChanged(fund?.carryForward, requestFund?.carriedForward, 'l')
                    )}
                >
                    {!isNaN(Number(requestFund?.carriedForward))
                        ? formatNumber(Number(requestFund?.carriedForward))
                        : requestFund?.carriedForward}
                    {currency}
                </p>
            </div>

            <div className="flex-auto border-l-2 border-neutral-oak-15 px-5 text-left">
                <p className="body-l-bold text-neutral-oak-dark">
                    {t('coInnovationFunds.fundsManagement.expiredFunds')}
                </p>
                <p className="body-s-book pt-3 leading-6 text-basic-blue">{currentTranslation}</p>
                <p className="body-l-book pt-0.5">
                    {!isNaN(Number(fund?.expired)) ? formatNumber(Number(fund?.expired)) : fund?.expired} {currency}
                </p>

                <p className={classNames(valueHasChanged(fund?.expired, requestFund?.expired, 's'), labelClasses)}>
                    {requestedTranslation}
                </p>
                <p className={classNames(valueClasses, valueHasChanged(fund?.expired, requestFund?.expired, 'l'))}>
                    {!isNaN(Number(requestFund?.expired))
                        ? formatNumber(Number(requestFund?.expired))
                        : requestFund?.expired}{' '}
                    {currency}
                </p>
            </div>

            <div
                aria-label="Fund Details Actions"
                className="flex items-end justify-center gap-3 border-l-2 border-neutral-oak-15 px-8 text-left"
            >
                <FundDetailActions requester={requester} statusId={fund?.statusId} handleActionClick={onActionClick} />
            </div>
        </div>
    );
};
