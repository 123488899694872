import { useQuery } from '@tanstack/react-query';

import { getValidators } from '../api';
import { validatorListParser } from '../domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'validators';

export const useValidatorsQuery = () =>
    useQuery([queryKey], async () => getValidators(), {
        suspense: false,
        onSuccess: data => saveToCache(queryKey, data),
        placeholderData: () => retrieveFromCache(queryKey, validatorListParser.parse),
    });
