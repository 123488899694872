import { useQuery } from '@tanstack/react-query';

import { getFundById } from '../api';
import { coInnovationFundParser } from '../domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'fund';

export const useGetFundByIdQuery = (id: string) => {
    return useQuery([queryKey, id], async () => getFundById(id), {
        onSuccess: data => saveToCache(`${queryKey}-${id}`, data),
        placeholderData: () => retrieveFromCache(`${queryKey}-${id}`, coInnovationFundParser.parse),
    });
};
