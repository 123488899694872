import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DialogTypes, SelectedFundState } from '../domain';

import FundCommentsSection from './FundCommentsSection';
import { FundDetailSection } from './FundDetailSection';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { fundStatuses } from '@features/coInnovationFunds/funds/constants';
import FundStatusFlow from '@features/coInnovationFunds/funds/Detail/FundStatusFlow';
import { PanelStatusDetail } from '@features/coInnovationFunds/funds/Detail/PanelStatusDetail';
import { PanelStatusDetailRequestChange } from '@features/coInnovationFunds/funds/Detail/PanelStatusDetailRequestChange';
import { TopDetail } from '@features/coInnovationFunds/funds/Detail/TopDetail';
import { FundsDialogs } from '@features/coInnovationFunds/funds/dialogs/FundsDialogs';
import { useGetFundByIdQuery } from '@features/coInnovationFunds/funds/hooks/useGetFundByIdQuery';
import { namespaces } from '@shared/constants';
import { BreadCrums } from '@shared/ui/breadCrums';

const canViewFundDetailPermissions = [permissions.coInnovationFunds.fundsManagement.view];

export const FundDetail = () => {
    const { fundId } = useParams<{ fundId?: string }>();
    const { data: fund } = useGetFundByIdQuery(fundId ?? '');

    const [selectedFundState, setSelectedFundState] = useState<SelectedFundState>();

    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const [initialPage, setInitialPage] = useState();

    const handleActionClick = useCallback(
        (actionType: DialogTypes) => {
            setSelectedFundState(previousState => {
                const id = previousState?.id || fund?.id || '';
                const types = previousState?.types.includes(actionType)
                    ? [...previousState.types]
                    : [...(previousState?.types || []), actionType];

                return { id, types };
            });
        },
        [fund?.id]
    );

    useEffect(() => {
        const savedPagination = sessionStorage.getItem('fundTablePagination');
        if (savedPagination) {
            const paginationObject = JSON.parse(savedPagination);
            const pageNumber = paginationObject.page;
            setInitialPage(pageNumber);
        }
    }, []);

    return (
        <div className="flex flex-col" data-testid="fund-detail">
            <BreadCrums
                breadcrums={[
                    { name: 'Funds Management', link: `../funds-management?page=${initialPage}` },
                    { name: 'Fund Detail page' },
                ]}
            />

            <HasPermission neededPermissions={canViewFundDetailPermissions}>
                <div className="mb-20 flex w-full flex-col gap-6 px-8 pb-6 pt-9">
                    {fund && <TopDetail data={fund} />}

                    {fund?.statusId === fundStatuses.changeRequestPending ? (
                        <PanelStatusDetailRequestChange
                            onActionClick={handleActionClick}
                            fund={fund}
                            lastStatus={fundStatuses.approved}
                        />
                    ) : (
                        <PanelStatusDetail fund={fund} onActionClick={handleActionClick} />
                    )}

                    <div className="flex flex-1 flex-col">
                        <div className="px-4"></div>
                        <div className="flex max-h-[60vh] min-h-[400px] flex-grow flex-col gap-2 overflow-hidden md:flex-row md:gap-4">
                            {fund && (
                                <>
                                    <FundDetailSection title={t('coInnovationFunds.fundsManagement.statusFlow')}>
                                        <FundStatusFlow fund={fund} />
                                    </FundDetailSection>
                                    <FundDetailSection title={t('coInnovationFunds.fundsManagement.comments')}>
                                        <FundCommentsSection fund={fund} />
                                    </FundDetailSection>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </HasPermission>
            <FundsDialogs
                selectedFundState={selectedFundState}
                setSelectedFundState={setSelectedFundState}
                fund={fund}
            />
        </div>
    );
};
