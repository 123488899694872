import produce from 'immer';
import { StateCreator } from 'zustand';

import { ToastProps } from './domain';

export type Slice = {
    toast: {
        toastInfo: ToastProps;
        setToastInfo: (toast: ToastProps) => void;
    };
};

const emptyToastInfo: ToastProps = { type: '', message: '' };

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    toast: {
        toastInfo: emptyToastInfo,
        setToastInfo: (toast: ToastProps) =>
            set(state =>
                produce(state, draft => {
                    draft.toast.toastInfo = toast;
                })
            ),
    },
});
