import { Suspense, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';

import { FundValidationForm } from '../forms/FundValidationForm';
import { useValidationMutation } from '../hooks/useValidationMutation';

import { fundStatuses } from '@features/coInnovationFunds/funds/constants';
import { namespaces } from '@shared/constants';
import {
    Button,
    DialogBody,
    DialogContainer,
    DialogError,
    DialogFooter,
    DialogHeader,
    Link,
    Spinner,
} from '@shared/ui';
import { useStore } from '@store';

type Props = {
    id: string;
    isOpen: boolean;
    modalType: 'approve' | 'reject';
    onClose: () => void;
    onSuccessToast?: () => void;
    onErrorToast?: () => void;
};

export const FundValidationDialog = ({ isOpen, id, modalType, onClose, onSuccessToast, onErrorToast }: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const { t: commonT } = useTranslation();

    const [comment, setComment] = useState<string>('');
    const [backendError, setBackendError] = useState(false);
    const supportUrl = useStore(state => state.serviceNow.supportUrl);
    const queryClient = useQueryClient();
    const { mutate: mutateValidationFund, isLoading } = useValidationMutation({
        id,
        comment,
        fundStatusId: modalType === 'approve' ? fundStatuses.approved : fundStatuses.rejected,
        onSuccess: () => {
            setBackendError(false);
            queryClient.invalidateQueries(['summary']);
            queryClient.invalidateQueries(['summaryPartners']);
            queryClient.invalidateQueries(['summaryPartnersHistory']);
            queryClient.invalidateQueries(['fundsList']);
            queryClient.invalidateQueries(['fund']);
            onSuccessToast && onSuccessToast();
            onClose();
        },
        onError: () => {
            setBackendError(true);
            onErrorToast && onErrorToast();
        },
    });

    if (!isOpen) {
        return <></>;
    }

    return (
        <DialogContainer showDialog={isOpen} setShowDialog={onClose}>
            <DialogHeader onCloseButtonClick={onClose}>
                {modalType === 'approve'
                    ? t('coInnovationFunds.validationFundsForm.approveTitle')
                    : t('coInnovationFunds.validationFundsForm.rejectTitle')}
            </DialogHeader>
            <DialogBody>
                <Suspense fallback={<Spinner />}>
                    {isLoading ? <Spinner /> : <FundValidationForm id={id} setComment={setComment} />}
                </Suspense>
            </DialogBody>

            <DialogFooter noPadding>
                <div className="flex p-5 md:block [&_button]:py-[8.5px] [&_button]:text-xs [&_button[type='submit']]:py-[8.5px] [&_button[type='submit']]:text-xs">
                    <Button
                        disabled={!comment || comment === ''}
                        variant="primary"
                        className="flex-grow"
                        type="submit"
                        onClick={() => mutateValidationFund()}
                    >
                        {modalType === 'approve'
                            ? t('coInnovationFunds.validationFundsForm.approveFunds')
                            : t('coInnovationFunds.validationFundsForm.rejectFunds')}
                    </Button>
                </div>

                {backendError && !isLoading ? (
                    <DialogError
                        title={commonT('errors.addFunds.title')}
                        instruction={
                            <Trans
                                t={commonT}
                                i18nKey="errors.instruction"
                                components={{
                                    anchor: (
                                        <Link
                                            to={supportUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            noPadding
                                            alignBaseLine
                                        />
                                    ),
                                }}
                            />
                        }
                    />
                ) : null}
            </DialogFooter>
        </DialogContainer>
    );
};
