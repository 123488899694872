import { CoInnovationFund } from '../domain';

import FundDetailComment from './FundDetailComment';

enum CommentContext {
    Pending = 'coInnovationFunds.fundsManagement.statuses.pending',
    Approved = 'coInnovationFunds.fundsManagement.statuses.approved',
    Rejected = 'coInnovationFunds.fundsManagement.statuses.rejected',
    Canceled = 'coInnovationFunds.fundsManagement.statuses.canceled',
    ChangeRequestPending = 'coInnovationFunds.fundsManagement.statuses.cRPending',
}

type FundCommentsSectionProps = {
    fund: CoInnovationFund;
};

function FundCommentsSection({ fund }: FundCommentsSectionProps) {
    return (
        <div className="flex max-h-fit flex-col gap-3.5 overflow-auto pb-4 pl-7">
            {fund?.submissionComment && (
                <FundDetailComment
                    key={fund.submissionDate}
                    name={fund.submittedByContact.name}
                    date={fund.submissionDate}
                    email={fund.submittedByContact.email}
                    text={fund.submissionComment.text}
                    photo={fund.submittedByContact.photo}
                    commentContext={CommentContext.Pending}
                />
            )}
            {fund?.validationComment && fund.validatedByContact && (
                <FundDetailComment
                    key={fund.validationDate}
                    name={fund.validatedByContact.name}
                    email={fund.validatedByContact.email}
                    date={fund.validationDate}
                    text={fund.validationComment.text}
                    photo={fund.validatedByContact.photo}
                    commentContext={[1, 4].includes(fund.statusId) ? CommentContext.Approved : CommentContext.Rejected}
                />
            )}
            {fund?.changeRequests?.map(x => (
                <div className="flex flex-col gap-3.5" key={x.submissionDate}>
                    {x.submissionComment && (
                        <FundDetailComment
                            key={x.creationDate}
                            name={x.submittedByContact?.name}
                            email={x.submittedByContact?.email}
                            date={x.submissionDate}
                            text={x.submissionComment}
                            photo={x.submittedByContact.photo}
                            commentContext={CommentContext.ChangeRequestPending}
                        />
                    )}
                    {x.validationComment && x.validatedByContact && (
                        <FundDetailComment
                            key={x.validationDate}
                            name={x.validatedByContact?.name}
                            email={x.validatedByContact?.email}
                            date={x.validationDate}
                            text={x.validationComment}
                            photo={x.validatedByContact.photo}
                            commentContext={
                                x.changeRequestStatusId === 1 ? CommentContext.Approved : CommentContext.Rejected
                            }
                        />
                    )}
                </div>
            ))}
        </div>
    );
}

export default FundCommentsSection;
