import { useNavigate } from 'react-router-dom';

type Option = {
    name: string;
    url: string;
    selected: boolean;
};

type Props = {
    options: Option[];
    onOptionClick: (url: string) => void; // Add this prop
};

const SegmentedButton = ({ options, onOptionClick }: Props) => {
    const navigate = useNavigate();

    const handleClick = (url: string) => {
        onOptionClick ? onOptionClick(url) : navigate(`?page=${url}#section`);
    };

    return (
        <nav
            className="w-full rounded bg-neutral-oak-15 p-[6px] text-neutral-oak-dark"
            aria-label="Segmented navigation"
        >
            <ul className="flex h-9 flex-row items-stretch justify-between gap-[6px]">
                {options.map(option => (
                    <li
                        key={option.name}
                        className={`flex-1 rounded ${
                            option.selected ? 'bg-basic-blue text-secondary-white' : 'hover:bg-neutral-oak-30'
                        }`}
                    >
                        <button
                            className={`flex h-full w-full items-center justify-center rounded text-sm font-bold ${
                                option.selected ? '' : 'active:bg-basic-blue active:text-white'
                            }`}
                            onClick={() => handleClick(option.url)}
                            aria-current={option.selected ? 'page' : undefined}
                        >
                            {option.name}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export { SegmentedButton };
