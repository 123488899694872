import { Dispatch, SetStateAction } from 'react';

import { PaginationState } from '@tanstack/react-table';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { fundStatusLabels } from '../constants';
import { SelectedFundState } from '../domain';

import { CoInnovationFundTableData } from './FundTable';

import { HasPermission, permissions } from '@features/authorization';
import { namespaces } from '@shared/constants';
import { formatDate, formatNumber } from '@shared/formatting';
import { Pagination } from '@shared/ui';
import ActionButton from '@shared/ui/button/ActionButton';
import { Column } from '@shared/ui/modularTable/column';
import { ExpandableRow } from '@shared/ui/modularTable/expandableRow';
import { ModularTable } from '@shared/ui/modularTable/modularTable';

type Props = {
    data: CoInnovationFundTableData[];
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
    pagination: PaginationState;
    totalItems: number;
    firstItemIndex?: number;
    lastItemIndex?: number;
    pageCount?: number;
    paginationCallback: (pagination: PaginationState) => void;
};

export const FundMobileTable = ({
    data,
    setSelectedFundState,
    pagination,
    totalItems,
    paginationCallback,
    firstItemIndex,
    lastItemIndex,
    pageCount,
}: Props) => {
    const navigate = useNavigate();
    const commonClasses = 'grid grid-cols-[1fr,1fr,auto] border-b-2 border-sky-100 p-3';
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const canCancelFundsPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];
    const canValidateFundsPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

    const canChangeRequestPermissions = [
        permissions.coInnovationFunds.allRequests.create,
        permissions.coInnovationFunds.partnerRequests.create,
    ];

    return (
        <div className="w-full">
            <ModularTable className="mb-5 gap-2">
                {data.map((fund, index) => (
                    <ExpandableRow key={index} className="rounded-lg border border-sky-100">
                        <Column
                            className={classNames(commonClasses, 'bg-sky-100')}
                            valueClassName="text-blue-850 underline underline-offset-2"
                            label={t('coInnovationFunds.fundsManagement.submitDate')}
                            value={fund.submissionDate}
                            onValueClick={() => navigate(`/co-innovation-funds/funds-management/${fund.id}`)}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.submitter')}
                            value={fund.submittedByContact}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.partner')}
                            value={fund.partner}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.provided')}
                            value={`${formatNumber(fund.provided)} CHF`}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.providedPlusCarryForward')}
                            value={`${formatNumber(fund.providedPlusCarryForward)} CHF`}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.available')}
                            value={`${formatNumber(fund.available ?? 0)} CHF`}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.expired')}
                            value={`${formatNumber(fund.expired)} CHF`}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.beginDate')}
                            value={formatDate(fund.beginDate)}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.consumptionExpirationDate')}
                            value={formatDate(fund.consumptionExpirationDate)}
                        />
                        <Column
                            className={commonClasses}
                            label={t('coInnovationFunds.fundsManagement.status')}
                            value={fund.statusId}
                        />

                        <div className="flex">
                            {[fundStatusLabels.pending, fundStatusLabels.changeRequestPending].includes(
                                fund.statusId
                            ) && (
                                <>
                                    <HasPermission neededPermissions={canCancelFundsPermissions}>
                                        <ActionButton
                                            onClickAction={() =>
                                                setSelectedFundState({ id: fund.id, types: ['cancel'] })
                                            }
                                            type="cancel"
                                            iconPosition="left"
                                        />
                                    </HasPermission>

                                    <HasPermission neededPermissions={canValidateFundsPermissions}>
                                        <ActionButton
                                            onClickAction={() =>
                                                setSelectedFundState({ id: fund.id, types: ['reject'] })
                                            }
                                            type="reject"
                                            iconPosition="left"
                                        />
                                    </HasPermission>
                                    <HasPermission neededPermissions={canValidateFundsPermissions}>
                                        <ActionButton
                                            onClickAction={() =>
                                                setSelectedFundState({ id: fund.id, types: ['approve'] })
                                            }
                                            type="approve"
                                            iconPosition="left"
                                        />
                                    </HasPermission>
                                </>
                            )}

                            {[fundStatusLabels.approved].includes(fund.statusId) && (
                                <HasPermission neededPermissions={canChangeRequestPermissions}>
                                    <ActionButton
                                        onClickAction={() =>
                                            setSelectedFundState({ id: fund.id, types: ['changeRequest'] })
                                        }
                                        type="request"
                                        iconPosition="left"
                                    />
                                </HasPermission>
                            )}
                        </div>
                    </ExpandableRow>
                ))}
                <div className="mb-11 w-full pb-5">
                    <Pagination
                        pagination={pagination}
                        totalItems={totalItems}
                        paginationCallback={paginationCallback}
                        paginationDetail={true}
                        firstItemIndex={firstItemIndex ?? 0}
                        lastItemIndex={lastItemIndex ?? 0}
                        pageCount={pageCount ?? 0}
                    />
                </div>
            </ModularTable>
        </div>
    );
};
