import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { SelectedFundState, SortParams } from '../domain';
import { useGetFundsQuery } from '../hooks/useGetFundsQuery';

import { FundKpis } from './FundKpis';
import { FundTable } from './FundTable';

import { FundFilterDialog } from '@features/coInnovationFunds/funds/dialogs/FundFilterDialog';
import { FundsDialogs } from '@features/coInnovationFunds/funds/dialogs/FundsDialogs';
import { PageType } from '@features/coInnovationFunds/shared/filtering/domain';
import { namespaces } from '@shared/constants';
import { defaultPageSize } from '@shared/constants/paging';
import { formatNumber } from '@shared/formatting';
import { usePagination, usePaginationTotals } from '@shared/ui/pagination/usePagination';
import { useStore } from '@store';

export const Funds = () => {
    const [sort, setsort] = useState<SortParams>({
        sorting: { id: '', desc: false },
    });

    const { setPage } = useStore(state => state.coInnovationFunds);

    const {
        pagination: { pageIndex, pageSize },
        setPagination,
        firstItemIndex,
    } = usePagination({ pageSize: defaultPageSize });

    const { data: funds, isFetching } = useGetFundsQuery({
        pageIndex,
        pageSize,
        sort,
        paginationReset: () => {
            setPagination(prevState => ({ pageIndex: 0, pageSize: prevState.pageSize }));
        },
    });

    const { pageCount, lastItemIndex } = usePaginationTotals({
        totalItems: funds?.total || 0,
        pagination: { pageIndex, pageSize },
    });

    const [selectedFundState, setSelectedFundState] = useState<SelectedFundState>();

    const selectedFund = funds?.items.find(({ id }) => id === selectedFundState?.id);

    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const totalProvided = formatNumber(funds?.totalProvided ?? 0);
    const totalAvailable = formatNumber(funds?.totalAvailable ?? 0);
    const availablePercentage = formatNumber(
        ((funds?.totalAvailable ?? 0) / (funds?.totalProvided === 0 ? 1 : funds?.totalProvided ?? 1)) * 100
    );
    const totalExpired = formatNumber(funds?.totalExpired ?? 0);
    const expiredPercentage = formatNumber(
        ((funds?.totalExpired ?? 0) / (funds?.totalProvided === 0 ? 1 : funds?.totalProvided ?? 1)) * 100
    );

    useEffect(() => {
        setPage(PageType.Fund);
    }, []);

    const sections = [
        {
            title: t('coInnovationFunds.fundsManagement.totalProvided'),
            value: `${totalProvided} CHF`,
            titleTestId: 'totalProvided-title',
            valueTestId: 'totalProvided-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.totalAvailable'),
            value: `${totalAvailable} CHF`,
            subValue: `${availablePercentage}% ${t('coInnovationFunds.summary.allocated.allocatedPercentage')}`,
            titleTestId: 'totalAllocated-title',
            valueTestId: 'totalAllocated-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.totalExpired'),
            value: `${totalExpired} CHF`,
            subValue: `${expiredPercentage}% ${t('coInnovationFunds.summary.expired.expiredPercentage')}`,
            titleTestId: 'totalExpired-title',
            valueTestId: 'totalExpired-value',
        },
    ];

    return (
        <div className="w-full px-6 md:px-8">
            <div className="pt-2">
                <FundKpis sections={sections} />
            </div>
            <div className="flex flex-grow flex-row">
                <FundTable
                    funds={funds}
                    isFetching={isFetching}
                    sort={sort}
                    setSort={setsort}
                    setSelectedFundState={setSelectedFundState}
                    setPagination={setPagination}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    firstItemIndex={firstItemIndex}
                    lastItemIndex={lastItemIndex}
                    pageCount={pageCount}
                />
                <FundFilterDialog />
                <FundsDialogs
                    selectedFundState={selectedFundState}
                    setSelectedFundState={setSelectedFundState}
                    fund={selectedFund}
                />
            </div>
        </div>
    );
};
