import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { CoInnovationFund, SelectedFundState } from '../domain';

import { ChangeRequestValidationDialog } from './ChangeRequestValidationDialog';
import { FundCancelDialog } from './FundCancelDialog';
import { FundChangeRequestCancelDialog } from './FundChangeRequestCancelDialog';
import { FundChangeRequestDialog } from './FundChangeRequestDialog';
import { FundValidationDialog } from './FundValidationDialog';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { useStore } from '@store';

const canCancelFundPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];
const canValidateFundPermissions = [permissions.coInnovationFunds.fundsManagement.validation];
const canChangeRequestPermissions = [
    permissions.coInnovationFunds.allRequests.create,
    permissions.coInnovationFunds.partnerRequests.create,
];
const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

type FundDialogsProps = {
    selectedFundState: SelectedFundState | undefined;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
    fund: CoInnovationFund | undefined;
};

export const FundsDialogs = ({ setSelectedFundState, selectedFundState, fund }: FundDialogsProps) => {
    const { setToastInfo } = useStore(state => state.toast);

    const dialogState = useMemo(
        () => ({
            isCancel: selectedFundState?.types.includes('cancel'),
            isChangeRequest: selectedFundState?.types.includes('changeRequest'),
            isCancelCR: selectedFundState?.types.includes('cancelCR'),
            approveOrReject: selectedFundState?.types.find(type => ['approve', 'reject'].includes(type)),
            isApprove: selectedFundState?.types.includes('approve'),
            approveOrRejectCR: selectedFundState?.types.find(type => ['approveCR', 'rejectCR'].includes(type)),
            isApproveCR: selectedFundState?.types.includes('approveCR'),
        }),
        [selectedFundState]
    );

    const onClose = useCallback(
        (typeToRemove: string) => {
            setSelectedFundState(prevState => ({
                id: fund?.id || '',
                types: prevState?.types?.filter(type => type !== typeToRemove) || [],
            }));
        },
        [setSelectedFundState, fund?.id]
    );

    if (!selectedFundState) {
        return null;
    }

    return (
        <>
            {dialogState.isCancel && (
                <HasPermission neededPermissions={canCancelFundPermissions}>
                    <FundCancelDialog
                        setSelectedFundState={setSelectedFundState}
                        selectedFund={fund}
                        onSuccessToast={() => setToastInfo({ type: 'reject', message: 'cancelFund' })}
                        onErrorToast={() => setToastInfo({ type: 'reject', message: 'error' })}
                    />
                </HasPermission>
            )}

            {dialogState.approveOrReject && (
                <HasPermission neededPermissions={canValidateFundPermissions}>
                    <FundValidationDialog
                        isOpen={!!dialogState.approveOrReject}
                        id={selectedFundState?.id || ''}
                        modalType={dialogState.isApprove ? 'approve' : 'reject'}
                        onSuccessToast={() =>
                            setToastInfo({
                                type: dialogState.isApprove ? 'approve' : 'reject',
                                message: dialogState.approveOrReject === 'approve' ? 'approveFund' : 'rejectFund',
                            })
                        }
                        onErrorToast={() => setToastInfo({ type: 'reject', message: 'error' })}
                        onClose={() => onClose(dialogState.approveOrReject!)}
                    />
                </HasPermission>
            )}

            {dialogState.isChangeRequest && (
                <HasPermission neededPermissions={canChangeRequestPermissions}>
                    <FundChangeRequestDialog
                        setSelectedFundState={setSelectedFundState}
                        onSuccessToast={() => setToastInfo({ type: 'approve', message: 'addCR' })}
                        onErrorToast={() => setToastInfo({ type: 'reject', message: 'error' })}
                        selectedFund={fund}
                    />
                </HasPermission>
            )}

            {dialogState.isCancelCR && (
                <HasPermission neededPermissions={canCancelFundPermissions}>
                    <FundChangeRequestCancelDialog
                        setSelectedFundState={setSelectedFundState}
                        selectedFund={fund}
                        onSuccessToast={() => setToastInfo({ type: 'reject', message: 'cancelCR' })}
                        onErrorToast={() => setToastInfo({ type: 'reject', message: 'error' })}
                    />
                </HasPermission>
            )}

            {dialogState.approveOrRejectCR && (
                <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                    <ChangeRequestValidationDialog
                        isOpen={!!dialogState.approveOrRejectCR}
                        id={selectedFundState.id}
                        modalType={dialogState.isApproveCR ? 'approve' : 'reject'}
                        onSuccessToast={() =>
                            setToastInfo({
                                type: dialogState.isApproveCR ? 'approve' : 'reject',
                                message: dialogState.approveOrRejectCR === 'approveCR' ? 'approveCR' : 'rejectCR',
                            })
                        }
                        onErrorToast={() => setToastInfo({ type: 'reject', message: 'error' })}
                        onClose={() => onClose(dialogState.approveOrRejectCR!)}
                        selectedFund={fund}
                    />
                </HasPermission>
            )}
        </>
    );
};
