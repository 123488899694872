import { Suspense, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';

import { CoInnovationFundListItem } from '../../funds/domain';
import { ChangeRequestValidationForm } from '../forms/ChangeRequestValidationForm';
import { useCrValidationMutation } from '../hooks/useCrValidationMutation';

import { namespaces } from '@shared/constants';
import { TextArea } from '@shared/form';
import {
    Button,
    DialogBody,
    DialogContainer,
    DialogError,
    DialogFooter,
    DialogHeader,
    Link,
    Spinner,
} from '@shared/ui';
import { useStore } from '@store';

type Props = {
    id: string;
    isOpen: boolean;
    modalType: 'approve' | 'reject';
    onClose: () => void;
    selectedFund?: CoInnovationFundListItem;
    onSuccessToast?: () => void;
    onErrorToast?: () => void;
};

export const ChangeRequestValidationDialog = ({
    isOpen,
    id,
    modalType,
    onClose,
    selectedFund,
    onSuccessToast,
    onErrorToast,
}: Props) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const { t: commonT } = useTranslation();

    const [backendError, setBackendError] = useState(false);
    const [comment, setComment] = useState('');
    const supportUrl = useStore(state => state.serviceNow.supportUrl);
    const queryClient = useQueryClient();
    const { mutate: mutateValidationChangeRequest, isLoading } = useCrValidationMutation({
        id,
        approved: modalType === 'approve' ? true : false,
        comment: comment,
        onSuccess: () => {
            setBackendError(false);
            queryClient.invalidateQueries(['summary']);
            queryClient.invalidateQueries(['summaryPartners']);
            queryClient.invalidateQueries(['summaryPartnersHistory']);
            queryClient.invalidateQueries(['fundsList']);
            queryClient.invalidateQueries(['fund']);
            onSuccessToast && onSuccessToast();

            onClose();
        },
        onError: () => {
            setBackendError(true);
            onErrorToast && onErrorToast();
        },
    });

    if (!isOpen) {
        return <></>;
    }

    return (
        <DialogContainer showDialog={isOpen} setShowDialog={onClose}>
            <DialogHeader onCloseButtonClick={onClose}>
                {modalType === 'approve'
                    ? t('coInnovationFunds.validationFundsForm.approveChangeRequest')
                    : t('coInnovationFunds.validationFundsForm.rejectChangeRequest')}
            </DialogHeader>
            <DialogBody>
                <Suspense fallback={<Spinner />}>
                    {!selectedFund ? <Spinner /> : <ChangeRequestValidationForm id={id} selectedFund={selectedFund} />}
                </Suspense>
                <TextArea
                    id={'validationComment'}
                    label={`${t('coInnovationFunds.addFundsForm.commentsLabel')}*`}
                    placeholder={t('coInnovationFunds.addFundsForm.commentsLabelDescription')}
                    onChange={x => setComment(x.target.value)}
                    rows={3}
                    required={true}
                />
            </DialogBody>

            <DialogFooter noPadding>
                <div className="flex p-5 md:block [&_button]:py-[8.5px] [&_button]:text-xs [&_button[type='submit']]:py-[8.5px] [&_button[type='submit']]:text-xs">
                    <Button
                        disabled={!comment || comment === ''}
                        variant="primary"
                        className="flex-grow"
                        type="submit"
                        onClick={() => mutateValidationChangeRequest()}
                    >
                        {modalType === 'approve'
                            ? t('coInnovationFunds.validationFundsForm.approveChangeRequest')
                            : t('coInnovationFunds.validationFundsForm.rejectChangeRequest')}
                    </Button>
                </div>

                {backendError && !isLoading ? (
                    <DialogError
                        title={commonT('errors.addFunds.title')}
                        instruction={
                            <Trans
                                t={commonT}
                                i18nKey="errors.instruction"
                                components={{
                                    anchor: (
                                        <Link
                                            to={supportUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            noPadding
                                            alignBaseLine
                                        />
                                    ),
                                }}
                            />
                        }
                    />
                ) : null}
            </DialogFooter>
        </DialogContainer>
    );
};
