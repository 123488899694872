import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { CoInnovationFundChangeRequestParam, CoInnovationFundListItem, SelectedFundState } from '../domain';
import { ChangeRequestFormState, FundChangeRequestForm } from '../forms/FundChangeRequestForm';
import { useChangeRequest } from '../hooks/useChangeRequest';

import { namespaces } from '@shared/constants';
import {
    Link,
    Button,
    Spinner,
    DialogBody,
    DialogError,
    DialogFooter,
    DialogHeader,
    DialogContainer,
} from '@shared/ui';
import { useStore } from '@store';

type Props = {
    selectedFund?: CoInnovationFundListItem;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
    onSuccessToast?: () => void;
    onErrorToast?: () => void;
};

const numberToDisplayString = (value: number | undefined): string => {
    return value?.toString().replace('.', ',') ?? '0';
};

const displayStringToNumberValidString = (value: string | undefined): string => {
    return value?.replace(',', '.') ?? '0';
};

const convertFormStateToParams = (
    params: Partial<ChangeRequestFormState>,
    defaultFormState: any
): CoInnovationFundChangeRequestParam => {
    return {
        fundId: defaultFormState.fundId,
        provided: displayStringToNumberValidString(params.provided),
        expired: displayStringToNumberValidString(params.expired),
        carriedForward: displayStringToNumberValidString(params.carriedForward),
        comment: params.submissionComment || '',
    };
};

export const FundChangeRequestDialog = ({
    setSelectedFundState,
    selectedFund,
    onSuccessToast,
    onErrorToast,
}: Props) => {
    const { t } = useTranslation();
    const { t: fundsManagementT } = useTranslation(namespaces.features.coInnovationFunds);
    const supportUrl = useStore(state => state.serviceNow.supportUrl);
    const queryClient = useQueryClient();

    const [backendError, setBackendError] = useState(false);

    const getDefaultFormState = useCallback(
        () => ({
            fundId: selectedFund?.id ?? '',
            provided: numberToDisplayString(selectedFund?.provided),
            expired: numberToDisplayString(selectedFund?.expired),
            carriedForward: numberToDisplayString(selectedFund?.carryForward),
            submissionComment: '',
        }),
        [selectedFund]
    );

    const form = useForm<ChangeRequestFormState>({
        mode: 'onChange',
        defaultValues: getDefaultFormState(),
    });

    const { watch, reset, handleSubmit } = form;

    useEffect(() => {
        reset(getDefaultFormState());
    }, [reset, selectedFund, getDefaultFormState]);

    const watchedFields = watch();
    const isFieldChanged =
        watchedFields.provided !== getDefaultFormState().provided ||
        watchedFields.expired !== getDefaultFormState().expired ||
        watchedFields.carriedForward !== getDefaultFormState().carriedForward;

    const isFormValid = watchedFields.submissionComment.trim() !== '' && isFieldChanged;

    const hideDialog = useCallback(() => {
        setSelectedFundState(prevState => ({
            id: selectedFund?.id || '',
            types: prevState?.types?.filter(type => type !== 'changeRequest') || [],
        }));
    }, [setSelectedFundState, selectedFund?.id]);

    const handleMutationSuccess = useCallback(() => {
        reset(getDefaultFormState());
        setBackendError(false);
        queryClient.invalidateQueries(['summary', 'summaryPartners', 'summaryPartnersHistory', 'fundsList', 'fund']);
        onSuccessToast?.();
        hideDialog();
    }, [reset, getDefaultFormState, queryClient, onSuccessToast, hideDialog]);

    const { mutate: mutateChangeRequest, isLoading } = useChangeRequest({
        changeRequest: convertFormStateToParams(watchedFields, getDefaultFormState()),
        onSuccess: handleMutationSuccess,
        onError: () => {
            setBackendError(true);
            onErrorToast?.();
        },
    });

    const onSubmit = useCallback(() => {
        if (isFormValid) {
            mutateChangeRequest();
        }
    }, [mutateChangeRequest, isFormValid]);

    if (!selectedFund) {
        return null;
    }

    return (
        <DialogContainer setShowDialog={hideDialog} showDialog={!!selectedFund} size="medium" disableBackgroundClick>
            <DialogHeader onCloseButtonClick={hideDialog}>
                {fundsManagementT('coInnovationFunds.fundsManagement.changeRequest.title')}
            </DialogHeader>

            <DialogBody>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Suspense fallback={<Spinner />}>
                        <FundChangeRequestForm selectedFund={selectedFund} form={form} />
                    </Suspense>
                )}
            </DialogBody>

            <DialogFooter noPadding>
                <div className="grid gap-3 p-5 md:block [&_button]:py-[8.5px] [&_button]:text-xs">
                    <Button
                        variant="primary"
                        className="flex-grow md:mr-2"
                        type="submit"
                        disabled={!isFormValid || isLoading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {fundsManagementT('coInnovationFunds.fundsManagement.changeRequest.proceed')}
                    </Button>
                    <Button
                        variant="secondary"
                        className="mr-4 flex-grow"
                        type="reset"
                        disabled={isLoading}
                        onClick={() => reset(getDefaultFormState())}
                    >
                        {t('forms.resetAll')}
                    </Button>
                </div>

                {backendError && !isLoading && (
                    <DialogError
                        title={t('errors.addFunds.title')}
                        instruction={
                            <Trans
                                t={t}
                                i18nKey="errors.instruction"
                                components={{
                                    anchor: (
                                        <Link
                                            to={supportUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            noPadding
                                            alignBaseLine
                                        />
                                    ),
                                }}
                            />
                        }
                    />
                )}
            </DialogFooter>
        </DialogContainer>
    );
};
